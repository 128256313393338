
<template>
    <div>
        <div id="component">
            <div class="filter-panel">
                <input
                    type="text"
                    placeholder="搜索路线名称"
                    v-model="queryParams.keyword"
                    class="cs-input"
                    style="margin-left: 0"
                />
                <template
                    v-if="[1001, 1002].includes(Number(staffInfo.dutyType))"
                >
                    <div class="cs-select" style="margin-right: 20px">
                        <select
                            @change="getStaffList"
                            v-model="queryParams.orgId"
                            style="padding-left: 5px;"
                        >
                            <option value="">全部部门</option>
                            <option
                                v-for="org in orgList"
                                :key="org.code"
                                :value="org.code"
                            >
                                {{ org.name }}
                            </option>
                        </select>
                        <i class="icon el-icon-caret-bottom"></i>
                    </div>
                    <div class="cs-select" style="margin-right: 30px">
                        <select
                            v-model="queryParams.operatorId"
                            style="padding-left: 5px;"
                        >
                            <option selected value="">
                                全部创建人
                            </option>
                            <option
                                v-for="staff in staffList"
                                :value="staff.id"
                                :key="staff.id"
                            >
                                {{ staff.name }}
                            </option>
                        </select>
                        <i class="icon el-icon-caret-bottom"></i>
                    </div>
                </template>
                <button
                    type="button"
                    class="btn btn-primary"
                    v-on:click="_queryInspectionRouteMethod()"
                    style="width: 50px; height: 30px; padding: 0;"
                >
                    查询
                </button>
            </div>
            <div class="result-panel">
                <CSTable :thead-top="filterHeight">
                    <template v-slot:header>
                        <div class="table-header-panel text-right">
                            <button
                                type="button"
                                class="btn btn-primary btn-sm sticky-right"
                                v-on:click="_openAddInspectionRouteModal()"
                            >
                                <svg
                                    class="icon"
                                    aria-hidden="true"
                                >
                                    <use xlink:href="#icon-menujiahao"></use>
                                </svg>
                                添加
                            </button>
                        </div>
                    </template>
                    <template v-slot:tbody>
                        <tr
                            v-for="(route, index) in inspectRouteArr"
                            :key="index"
                        >
                            <td class="text-center">
                                {{ route.createTime }}
                            </td>
                            <td class="text-center">
                                <div
                                    class="btn btn-link btn-content"
                                    @click="openViewModal(route.name)"
                                >
                                    {{ route.name }}
                                </div>
                            </td>
                            <td class="text-center">
                                <div
                                    class="btn btn-link btn-content"
                                    @click="openViewModal(route.id, 2)"
                                >
                                    {{ route.routePointCount }}
                                </div>
                            </td>
                            <td class="text-center">
                                {{ route.orgName }}
                            </td>
                            <td class="text-center">
                                {{ route.createBy }}
                            </td>
                            <td class="text-center">
                                <div
                                    class="btn-group"
                                >
                                    <button
                                        type="button"
                                        class="btn btn-primary dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        操作
                                    </button>
                                    <ul
                                        class="dropdown-menu"
                                        style="min-width: 76px; width: 76px;"
                                    >
                                        <li
                                            @click="_openAddInspectionRouteModal(route)"
                                        >
                                            <a
                                                style="width: 100%;"
                                            >修改</a>
                                        </li>
                                        <li
                                            @click="_openDeleteInspectionRouteModel(route)"
                                        >
                                            <a
                                                style="width: 100%;"
                                            >删除</a>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:thead>
                        <tr>
                            <th class="text-center">创建时间</th>
                            <th class="text-center">巡检路线</th>
                            <th class="text-center">巡检点</th>
                            <th class="text-center">部门</th>
                            <th class="text-center">创建人</th>
                            <th class="text-center">操作</th>
                        </tr>
                    </template>
                </CSTable>
                <Pagination />
            </div>
            <ViewModal name="viewModal" componentName="ViewModal"></ViewModal>
            <AddInspectionRoute
                name="addInspectionRoute"
                componentName="AddInspectionRoute"
            ></AddInspectionRoute>

        </div>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
import ViewModal from "@/components/ViewModal.vue";
import AddInspectionRoute from "@/components/AddInspectionRoute.vue";
import { ORG_LEVEL } from "@/constant";

const DEFAULT_PAGE = 1;
const DEFAULT_ROWS = 10;

import {
    queryInspectionRouteUrl,
    queryDepartmentUrl,
    queryInspectRoutePointUrl,
    queryDepartmentStaffUrl, deleteInspectRouteUrl,
} from "@/requestUrl";
import CSTable from "@/components/common/CSTable";
export default {
    props: {},
    components: {
        CSTable,
        Pagination,
        ViewModal,
        AddInspectionRoute,
    },
    created(){
        window.addEventListener("keydown",this._queryInspectionRouteMethodDown);
    },
    beforeDestroy(){
        window.removeEventListener("keydown",this._queryInspectionRouteMethodDown);
    },
    deactivated(){
        window.removeEventListener("keydown",this._queryInspectionRouteMethodDown);
    },
    data() {
        return {
            orgList: [],
            filterHeight: 0,
            staffList: [],
            staffInfo: this.$vc.getCurrentStaffInfo(),
            queryParams: {
                regionId: this.$vc.getCurrentRegion().communityId,
                keyword: "",
                operatorId: this.$vc.getCurrentStaffInfo().id,
                orgId: this.$vc.getCurrentStaffInfo().orgId,
                pageNo: 1,
                pageSize: 10,
            },
            inspectRouteArr: [],
        };
    },

    mounted() {
        const staffInfo = this.$vc.getCurrentStaffInfo();
        if ([1001, 1002].includes(Number(staffInfo.dutyType))) {
            this.queryParams.orgId = "";
        }
        this.getOrgList();
        this._listInspectionRoutes(DEFAULT_PAGE, DEFAULT_ROWS);
        this.$vc.on(this.$route.path,
            "inspectionRouteManage",
            "listInspectionRoute",
            (_param) => {
                this._listInspectionRoutes(
                    this.queryParams.pageNo,
                    DEFAULT_ROWS
                );
            }
        );
        this.$vc.on(this.$route.path, "inspectionRouteManage", "goBack", (_param) => {
            this.inspectionRouteManageInfo.inspectionPoint = false;
        });
        this.$vc.on(this.$route.path, "pagination", "page_event", (_currentPage) => {
            this._listInspectionRoutes(_currentPage, DEFAULT_ROWS);
        });
        this.getStaffList();
        this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        window.addEventListener('resize', () => {
            this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        })
    },
    methods: {
        _queryInspectionRouteMethodDown(e){
            if(e.keyCode == 13){
                this._queryInspectionRouteMethod();
            }
        },
        // 获取员工列表
        getStaffList() {
            this.$fly
                .post(queryDepartmentStaffUrl, {
                    regionCode: this.$vc.getCurrentRegion().code,
                    departmentCode: this.queryParams.orgId,
                    jobLevel: "",
                    isOnTheJob: true,
                })
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.staffList = res.data;
                });
        },
        async openViewModal(viewInfo, type = 1) {
            if (type === 1) {
                this.$CSDialog.alert({
                  title: '查看巡检路线',
                  message: viewInfo
                })
                return ;
            }
            if (type === 2) {
                const inspectPoints = await this.getInspectPoints(viewInfo);
                let messageHtml = '';
                let content = "";
                if (inspectPoints) {
                    inspectPoints.forEach((val, key) => {
                        content += `<div class=" text-center">${val.name}</div>`;
                    });
                    messageHtml = `<ul class="list-group" style="font-size: 24px;">${content}</ul>`;
                }
                this.$CSDialog.alert({
                    title: '查看巡检点',
                    messageHtml,
                })
            }
        },
        /**
         * 获取巡检路线上的巡检点
         * @param {Number} rid 巡检路线的id
         *  */
        getInspectPoints(rid) {
            return this.$fly
                .get(queryInspectRoutePointUrl, {
                    rid,
                })
                .then((res) => {
                    return res.data;
                });
        },
        getOrgList() {
            this.$fly
                .post(queryDepartmentUrl, {
                    regionCode: this.$vc.getCurrentRegion().code,
                    code: "",
                    name: "",
                    dutyType: "",
                    parentCode: "",
                    level: ORG_LEVEL.DEPARTMENT,
                    pageNo: 1,
                    pageSize: 100,
                })
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }

                    this.orgList = res.data;
                });
        },
        _listInspectionRoutes: function (_page = 1, _rows = 10) {
            this.queryParams.pageNo = _page;
            this.queryParams.pageSize = _rows;
            this.$fly
                .post(queryInspectionRouteUrl, this.queryParams)
                .then((res) => {
                    this.inspectRouteArr = res.data.datas;
                    if (_page === 1) {
                      this.$vc.emit(this.$route.path, "pagination", "init", {
                        currentPage: _page,
                        total: res.data.total,
                        pageSize: _rows,
                      });
                    }
                });
        },
        _openAddInspectionRouteModal: async function (params) {
            let inspectPoints;
            let paramsInfo;
            if (params) {
                inspectPoints = await this.getInspectPoints(params.id);
                paramsInfo = {
                    ...params,
                    inspectPoints,
                };
            }
            this.$vc.emit(this.$route.path,
                "addInspectionRoute",
                "openAddInspectionRouteModal",
                paramsInfo
            );
        },
        _openDeleteInspectionRouteModel: async function (_inspectionRoute) {
            this.$CSDialog.confirm({
                title: '提示',
                message: '确定删除吗?',
                onConfirm: () => {
                    this.$fly
                        .post(deleteInspectRouteUrl, {
                            routeId: _inspectionRoute.id,
                            regionId: this.$vc.getCurrentRegion().communityId,
                            operatorId: this.$vc.getCurrentStaffInfo().id,
                        })
                        .then((res) => {
                            this.$CSDialog.instance.closeDialog();
                            this._listInspectionRoutes();
                        });
                }
            })
        },
        _queryInspectionRouteMethod: function () {
            this._listInspectionRoutes(DEFAULT_PAGE, DEFAULT_ROWS);
        },
        _openInspectionPointModel: function (_inspectionRoute) {
            this.inspectionRouteManageInfo.inspectionPoint = true;
            this.$vc.emit(this.$route.path,
                "inspectionRoutePointManage",
                "listInspectionPoint",
                _inspectionRoute
            );
        },
        _moreCondition: function () {
            if (this.inspectionRouteManageInfo.moreCondition) {
                this.inspectionRouteManageInfo.moreCondition = false;
            } else {
                this.inspectionRouteManageInfo.moreCondition = true;
            }
        },
    },
};
</script>
<style>
</style>
